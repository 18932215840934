import React from "react"

export const TitleUnderlineRightOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="216" height="9" viewBox="0 0 216 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M40.0086 8.33102C35.5675 7.42759 3.00597 8.25375 0.632623 7.99818C-1.3092 7.64156 1.67547 1.47805 3.45548 0.568684C4.08477 0.24773 4.82192 0.18235 6.51202 0.354714C7.86052 0.485473 17.1382 0.467639 26.0921 0.402259C35.0461 0.33688 43.6765 0.259614 43.6765 0.259614C44.126 0.455753 45.5104 -0.180211 45.7621 0.0515895C46.1577 0.324995 60.3258 0.372544 74.9974 0.372544C89.669 0.372544 104.844 0.354711 107.217 0.360654C110.112 0.366598 107.793 0.717274 111.209 0.610289C118.473 0.384432 134.259 0.883691 139.761 0.616229C144.184 0.40226 145.119 0.414147 149.326 0.681609C153.605 0.955015 176.548 0.996621 179.209 0.622174C179.856 0.515189 180.342 0.562737 180.342 0.699439C180.647 0.71727 196.47 1.11549 196.523 1.21653C196.631 1.35324 197.117 1.4305 197.584 1.34135C198.447 1.21059 215.69 1.01445 215.906 1.41862C216.571 2.57762 213.568 7.38004 211.465 8.5331C209.253 9.75154 198.303 8.12894 189.242 8.73518C139.815 8.00412 149.65 8.06356 138.484 8.50338C137.496 8.07544 133.504 9.04425 131.131 8.29536C130.16 7.98629 123.058 7.86742 121.439 8.14677C119.659 8.45584 84.0772 7.30277 79.9059 8.17054C73.2893 7.40976 46.2656 8.19432 40.0086 8.33102Z" fill="#FFA700" />
    </svg>

  </span>
)

export const TitleUnderlineRightTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="235" height="9" viewBox="0 0 235 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M43.5278 8.33102C38.6961 7.42759 3.27038 8.25375 0.68827 7.99818C-1.42437 7.64156 1.82285 1.47805 3.75943 0.568684C4.44408 0.24773 5.24607 0.18235 7.08484 0.354714C8.55195 0.485473 18.6457 0.467639 28.3873 0.402259C38.1289 0.33688 47.5184 0.259614 47.5184 0.259614C48.0074 0.455753 49.5137 -0.180211 49.7875 0.0515895C50.2179 0.324995 65.6322 0.372544 81.5944 0.372544C97.5565 0.372544 114.066 0.354711 116.649 0.360654C119.798 0.366598 117.274 0.717274 120.991 0.610289C128.894 0.384432 146.069 0.883691 152.055 0.616229C156.867 0.40226 157.884 0.414147 162.461 0.681609C167.117 0.955015 192.077 0.996621 194.973 0.622174C195.677 0.515189 196.205 0.562737 196.205 0.699439C196.537 0.71727 213.752 1.11549 213.81 1.21653C213.928 1.35324 214.456 1.4305 214.964 1.34135C215.903 1.21059 234.663 1.01445 234.897 1.41862C235.621 2.57762 232.354 7.38004 230.066 8.5331C227.66 9.75154 215.747 8.12894 205.888 8.73518C152.113 8.00412 162.814 8.06356 150.666 8.50338C149.59 8.07544 145.247 9.04425 142.665 8.29536C141.609 7.98629 133.882 7.86742 132.122 8.14677C130.185 8.45584 91.4729 7.30277 86.9347 8.17054C79.7361 7.40976 50.3352 8.19432 43.5278 8.33102Z" fill="#FFA700" />
    </svg>
  </span>
)