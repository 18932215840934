import React from "react"

export const TitleUnderlineCobrosOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="296" height="11" viewBox="0 0 296 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M55.023 10.1944C48.9075 9.24445 4.10052 10.1225 0.844942 9.83124C-1.81242 9.4467 2.45974 2.85547 4.93104 1.89138C5.7973 1.54473 6.80244 1.49743 9.10437 1.66453C12.7542 1.97627 59.9639 1.55176 59.9639 1.55176C60.5865 1.75743 62.4589 1.07735 62.8015 1.32653C63.8807 1.90896 140.4 1.39093 146.926 1.3822C150.882 1.3771 147.752 1.76025 152.408 1.61775C162.332 1.3349 183.956 1.71652 191.476 1.38067C197.494 1.10309 198.791 1.09497 204.566 1.35699C210.467 1.59403 241.851 1.39338 245.44 0.941902C246.318 0.817838 246.992 0.864732 246.992 1.01097C246.992 1.04276 269.113 1.22903 269.214 1.34383C269.379 1.49066 270.053 1.56298 270.677 1.46345C271.835 1.31494 295.384 0.84498 295.751 1.27229C296.904 2.49716 293.642 7.68676 290.903 8.94239C288.099 10.2868 272.738 8.71289 260.383 9.4831L202.871 9.36252L190.542 9.82762C189.132 9.38391 183.696 10.471 180.417 9.67102C179.032 9.35457 169.237 9.27544 167.073 9.59843C165.189 9.84611 115.084 9.02685 109.941 9.89248C100.785 9.12256 63.6357 10.0468 55.023 10.1944Z" fill="#FFA700" />
    </svg>
  </span>
)

export const TitleUnderlineCobrosTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="158" height="11" viewBox="0 0 158 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M29.1889 10.1031C25.9462 9.16331 2.17501 10.1157 0.448431 9.82984C-0.960642 9.44971 1.31664 2.85143 2.62925 1.88325C3.08937 1.53516 3.62267 1.4862 4.84355 1.64947C6.77926 1.95515 31.8243 1.45232 31.8243 1.45232C32.1543 1.65695 33.1487 0.973768 33.3301 1.22238C33.9016 1.80301 74.4955 1.15803 77.9575 1.13847C80.0563 1.12681 78.3948 1.51516 80.8653 1.36493C86.1301 1.06562 97.6011 1.41136 101.591 1.06303C104.784 0.77547 105.472 0.765195 108.535 1.01764C111.665 1.24488 128.315 0.992156 130.219 0.534728C130.685 0.409208 131.043 0.454984 131.042 0.601224C131.042 0.633015 142.777 0.782573 142.831 0.897212C142.918 1.04376 143.275 1.11496 143.606 1.0144C144.221 0.863971 156.714 0.354942 156.908 0.781637C157.518 2.00459 155.779 7.19957 154.324 8.45974C152.834 9.8088 144.688 8.26038 138.132 9.05108L107.623 9.02593L101.082 9.51148C100.334 9.07011 97.4487 10.1663 95.7105 9.37168C94.976 9.05753 89.78 8.99465 88.6316 9.32123C87.6319 9.57203 61.0526 8.83591 58.3231 9.71007C53.467 8.95535 33.7581 9.9412 29.1889 10.1031Z" fill="#FFA700" />
    </svg>
  </span>
)

export const TitleUnderlineCobrosThree = ({ underline }) => (
  <span>
    {underline}
    <svg width="198" height="11" viewBox="0 0 198 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M36.6274 10.1294C32.5576 9.18667 2.72943 10.1177 0.5626 9.83024C-1.2059 9.44885 1.64578 2.8526 3.29202 1.88559C3.86908 1.53792 4.53823 1.48943 6.07039 1.65381C8.49966 1.96123 39.9267 1.48095 39.9267 1.48095C40.3409 1.68588 41.5881 1.00359 41.8159 1.25237C42.5336 1.83352 93.4717 1.22509 97.8159 1.20865C100.45 1.19888 98.365 1.58573 101.465 1.43772C108.071 1.14315 122.466 1.49922 127.472 1.15449C131.478 0.869804 132.342 0.860149 136.185 1.11535C140.114 1.34542 161.006 1.10768 163.395 0.651967C163.98 0.526867 164.429 0.572964 164.428 0.719205C164.428 0.750996 179.154 0.911122 179.221 1.02581C179.33 1.17244 179.779 1.24396 180.194 1.1437C180.965 0.99382 196.642 0.49604 196.886 0.922912C197.652 2.14642 195.474 7.33985 193.649 8.59871C191.782 9.94644 181.557 8.39068 173.332 9.17548L135.048 9.12285L126.84 9.6025C125.902 9.16046 122.282 10.254 120.1 9.45787C119.178 9.14306 112.658 9.0755 111.217 9.40105C109.963 9.65095 76.6099 8.89089 73.1857 9.7626C67.0913 9.00349 42.3609 9.9716 36.6274 10.1294Z" fill="#FFA700" />
    </svg>
  </span>
)